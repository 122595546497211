<template>
    <div>
      <div @click="openModal">
        <img
          src="../../assets/images/qr-code-scan-icon.svg"
          class="cursor-pointer"
          style="width: 20px; height: 24px;"
          alt="qr_code"
        >
      </div>
      <b-card
        v-if="showModalQRcode"
        v-model="showModalQRcode"
        class="qr-code"
      >
        <div class="qrcode-btn">
          <button
            class="btn-close"
            @click="closeModal"
          >
            X
          </button>
        </div>

        <div
          ref="qrCode"
          class="qr-code-content"
        >
          <div class="container">
            <div v-if="hasClass('dark-layout')">
              <qrcode-vue
                :value="generateQRCodeValue"
                :size="size"
                level="H"
                :background="'#212121ff'"
                :foreground="'#0564f2ff'"
              />
            </div>
            <div v-else>
              <qrcode-vue
                :value="generateQRCodeValue"
                :size="size"
                level="H"
                :background="'#f7f7f7ff'"
                :foreground="'#0564f2ff'"
              />
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </template>

  <script>
  import QrcodeVue from 'qrcode.vue'

  export default {
    components: {
      QrcodeVue,
    },

    data() {
      return {
        size: 200,
        showModalQRcode: false,
        showDark: false,
      }
    },
    computed: {
      generateQRCodeValue() {
        // Use the route params to dynamically generate the QR code value
        return `https://contact.gulftic.com/${this.$route.params.id}`
      },
    },
    mounted() {
      // Add event listener for clicks on the document
      document.addEventListener('click', this.handleClickOutside)





    },
    methods: {
      openModal(event) {
        event.stopPropagation()
        this.showModalQRcode = true
      },
      closeModal() {
        this.showModalQRcode = false
      },
      handleClickOutside(event) {
        // Check if the click originated from within the modal
        if (this.$refs.qrCode && !this.$refs.qrCode.contains(event.target)) {
          // Clicked outside the modal, so close it
          this.closeModal()
        }
      },
      hasClass(className) {
        // Check if the element has the specified class
        return document.querySelector(`.${className}`) !== null
      },
    },
  }
  </script>
  <style scoped>
  .qr-code {
    z-index: 1000;
    position: fixed;
    height: 550px;
    width: 94%;
    margin-top: 100px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    border-radius: 10px;
    left: 3%;
    top: 30%;
    transform: translateY(-50%);
    justify-content: center;
  }
  .dark-layout .qr-code {
    background-color: #212121ff;

  }
  .qrcode-btn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
.btn-close{
  background-color: #0564f2ff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
}
  .container {
    position: relative;
    height: 200px; /* Adjust height as needed */
  }
  .container::before {
    content: "";
    position: absolute;
    top: 25%;
    left: 28%;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    background-image: url("../../assets/images/logo/QR.png");
    background-size: cover;
    z-index: 0;
  }
  .dark-layout .container::before {
    content: "";
    position: absolute;
    top: 25%;
    left: 30%;
    width: 103px;
    height: 103px;
    border-radius: 50%;
    background-image: url("../../assets/images/logo/QR-logo.png");
    background-size: cover;
    z-index: 0;
  }
  </style>
