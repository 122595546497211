<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'Gulftic',
			author: 'Gulftic',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center">
		<div
			class="font-general-regular mt-5 text-md text-ternary-light dark:text-ternary-light"
		>
		Copyrights	&copy; {{ copyrightDate }} {{ projectName }} .
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
