<template>
  <AppHeader />

  <section
    class="flex flex-col sm:justify-between items-center sm:flex-row mt-4 sm:mt-10"
  >
    <!-- Banner left contents -->
    <div class="w-full md:w-1/3 text-left">
      <h1
        class="font-general-semibold text-2xl md:text-3xl xl:text-4xl text-center sm:text-left text-primary-dark dark:text-primary-light uppercase"
      >
        {{ device.name }}
      </h1>
      <p
        class="font-general-medium mt-1 text-md sm:text-xl xl:text-2xl text-center sm:text-left leading-none text-ternary-dark dark:text-ternary-light"
      >
        {{ device.brand }}
      </p>
    </div>

    <!-- Banner right illustration -->
    <div class="flex flex-wrap justify-center mt-4">
      <div class="conOfImg px-4">
        <img
          :src="device.image"
          :style="`border: 2px solid ${
            theme === 'light' ? '#1c9bf6' : '#ffffff'
          }`"
          alt="..."
          class="shadow max-w-full h-auto align-middle border-none"
        />
      </div>
    </div>

    <div
      class="px-4 py-4 sm:pt-30 border-primary-light dark:border-secondary-light"
      style="
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
      "
    >
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Device Id: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.gulftic_device_id
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Assigned employee: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.employee.name
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Model Number: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.model_number
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Serial Number: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.serial_number
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Purchased Date: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.purchased_date
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Assigned Date: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.assigned_date
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> status: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.status
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-20">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Maintenance Date: </strong></span
        >
        <span class="text-primary-dark dark:text-primary-light">{{
          device.maintenance_date
        }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-2">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Notes: </strong></span
        >
        <span class="text-primary-dark">{{ device.notes }}</span>
      </div>
      <div class="flex justify-center items-center my-1 sm:mb-2">
        <span class="px-2 text-primary-dark dark:text-primary-light"
          ><strong> Branch </strong></span
        >
        <span class="text-primary-dark">{{ device.branch.name }}</span>
      </div>
    </div>
  </section>
</template>

<script>
import feather from "feather-icons";
import AppHeader from "../components/shared/AppHeader";
import axios from "axios";

export default {
  components: {
    AppHeader
  },
  name: "Home",
  data: () => {
    return {
      theme: "",
      device: {
        gulftic_device_id: "",
        name: "",
        brand: "",
        model_number: "",
        serial_number: "",
        purchased_date: "",
        assigned_date: "",
        notes: "",
        unique_id: "",
        status: "",
        maintenance_date: "",
        image: null,
        employee: {
          name: ""
        },
        branch: {
          name: ""
        }
      }
    };
  },
  created() {
    this.theme = localStorage.getItem("theme") || "light";
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem("theme") || "light";
    const deviceId = this.$route.params.id;

    // Make a GET request to fetch data based on the employeeId
    this.fetchData(deviceId);
  },
  updated() {
    feather.replace();
  },
  methods: {
    fetchData(deviceId) {
      // Replace the URL with the actual API endpoint you want to fetch data from
      axios
        .get(
          `https://gulftic-vcard.tmam.app/api/v1/admin/device/show-by-unique-id/${deviceId}`
        )
        .then((response) => {
          this.device.name = response.data.data.name;
          this.device.gulftic_device_id = response.data.data.gulftic_device_id;
          this.device.brand = response.data.data.brand;
          this.device.model_number = response.data.data.model_number;
          this.device.serial_number = response.data.data.serial_number;
          this.device.purchased_date = response.data.data.purchased_date;
          this.device.assigned_date = response.data.data.assigned_date;
          this.device.unique_id = response.data.data.unique_id;
          this.device.status = response.data.data.status;
          this.device.notes = response.data.data.notes;
          this.device.branch.name = response.data.data.branch.name;
          this.device.maintenance_date = response.data.data.maintenance_date;
          this.device.image = response.data.data.image;
          this.device.employee.name = response.data.data.employee.name;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }
};
</script>

<style scoped>
.conOfImg {
  width: 200px;
  height: 165px;
}
.conOfImg img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: 10%;
}
.phone-link {
  white-space: nowrap;
  width: auto;
  display: inline-block;
}

@media (max-width: 767px) {
  .phone-link {
    white-space: nowrap;
    width: auto;
    display: inline-block;
  }
}
</style>
